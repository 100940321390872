import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.propsData.data), (val, index) => {
      return (_openBlock(), _createElementBlock("td", {
        key: index,
        class: _normalizeClass(`1-${val}`)
      }, [
        _createVNode(_component_el_input, {
          modelValue: _ctx.defaultObj[val],
          "onUpdate:modelValue": ($event: any) => ((_ctx.defaultObj[val]) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ], 2))
    }), 128))
  ]))
}