<template>
	<table id="grid-size-table">
		<slot />
	</table>
</template>

<style>
#grid-size-table {
	width: 100%;
}

#grid-size-table td,
#grid-size-table th {
	border: 1px solid #ddd;
	padding: 8px;
}
</style>
