
import { defineComponent, onMounted, ref, reactive, watch } from 'vue'
import { localizeGender } from '@/constains'
import { ElNotification } from 'element-plus'

import axios from '@/http'

import Table from '@/components/GridSizeTable/Table.vue'
import Th from '@/components/GridSizeTable/Th.vue'
import Row from '@/components/GridSizeTable/Row.vue'

export default defineComponent({
	name: 'GridSizeCreatePage',

	components: { Table, Th, Row },

	setup() {
		const isApply = ref(false)
		const renderComponent = ref(true)
		const isSelectedGender = ref(false)
		const brands = ref([])
		const genders = ref([])
		const { bulk, ...localizeGenderGrid } = localizeGender
		const categories = ref<any>([])
		const genderCategories = ref([])
		const mainH1 = reactive({
			brand: '',
			category: '',
			gender: '',
			status: false
		})

		const showTable = ref(false)

		/* eslint-disable */
		// @ts-nocheck
		const gridSizeRow = ref([])

		const generationObj = reactive({
			data: {}
		})

		const availableGrid = ref([
			{
				key: 'size',
				name: 'Размер',
				instock: false
			},
			{
				key: 'footSize',
				name: 'Размер стопы',
				instock: false
			},
			{
				key: 'intlSize',
				name: 'Международный размер (INT)',
				instock: false
			},
			{
				key: 'brandSize',
				name: 'Размер бренда',
				instock: false
			},
			{
				key: 'breast',
				name: 'Объем груди (см)',
				instock: false
			},
			{
				key: 'waist',
				name: 'Объем талии (см)',
				instock: false
			},
			{
				key: 'hip',
				name: 'Объем бедер (см)',
				instock: false
			},
			{
				key: 'russianSize',
				name: 'Российский размер',
				instock: false
			}
		])

		const filterForm = reactive({
			brand: '',
			category: '',
			gender: ''
		})

		const handleIsApply = ref<boolean>(true)

		watch(filterForm, () => {		
			const checkGender = +filterForm.gender === 0 || filterForm.gender
			if (filterForm.brand && checkGender && filterForm.category) {
				handleIsApply.value = false
			}
		})

		const updateRow = (update: any) => {
			gridSizeRow.value.forEach(i => {
				// @ts-ignore
				const isCurrent = update.id === i.id
				if (isCurrent) {
					Object.keys(update.data).forEach(k => {
						// @ts-ignore
						if (Object.prototype.hasOwnProperty.call(i.grid, k)) {
							// @ts-ignore
							i.grid[k] = update.data[k]
						}
					})
				}
			})
		}

		const onChangeGender = (update: any) => {
			isSelectedGender.value = true
			if (genderCategories.value.length)	{
				filterForm.category = ''
			}
		
			const arrCategories = Object.values(categories.value);
			// @ts-ignore
			genderCategories.value = arrCategories.filter((item: any) => item.gender === update)				
		}

		const showMainH1 = () => {
			brands.value.forEach((i: any) => {
				if (i.id === filterForm.brand) {
					mainH1.brand = i.name
				}
			})

			Object.values(localizeGenderGrid).forEach((i: any, index: any) => {
				if (index === filterForm.gender) {
					mainH1.gender = i
				}
			})

			mainH1.category = filterForm.category

			mainH1.status = true
		}

		const handleFilterForm = async () => {
			showTable.value = false
			try {
				const { data } = await axios.post('/grid-size/brand-category', {
					brandId: filterForm.brand,
					gender: filterForm.gender,
					categoryName: filterForm.category
				})
				
				availableGrid.value.forEach(i => (i.instock = false))

				generationObj.data = {}

				setTimeout(async () => {
					availableGrid.value.forEach(i => {
						if (Object.prototype.hasOwnProperty.call(data, i.key)) {
							i.instock = true
						}
					})

					availableGrid.value.forEach(i => {
						if (i.instock === true) {
							Object.assign(generationObj.data, { [i.key]: '' })
						}
					})

					gridSizeRow.value = []

					for (let i = 0; i < 50; i++) {
						// @ts-ignore
						gridSizeRow.value.push({ id: Math.floor(Math.random() * 100), grid: { ...generationObj.data } })
					}

					isApply.value = true
					showTable.value = true
					handleIsApply.value = false

					showMainH1()
				}, 500)
			} catch (e) {
				// @ts-ignore
				console.log(e.response)

				ElNotification({
					// @ts-ignore
					title: 'Данного набора параметров не найдено',
					// @ts-ignore
					type: 'error'
				})
			}
		}

		const resetFilterForm = () => {
			location.reload()
		}

		const handleSubmit = async () => {
			let catId = 0

			const grids: any = []

			// @ts-ignore
			gridSizeRow.value.forEach(i => grids.push(i.grid))

			genderCategories.value.forEach((i: any) => {
				// @ts-ignore
				if (i.name === filterForm.category) {
					// @ts-ignore
					catId = i.id
				}
			})

			try {
				const response = await axios.post('/grid-size/create', {
					brandId: filterForm.brand,
					gender: filterForm.gender,
					categoryId: catId,
					grid: grids
				})

				ElNotification({
					title: 'Размерная сетка создано!',
					type: 'success'
				})

				if (response.status === 201) {
					setTimeout(() => {
						// location.reload()
					}, 1000)
				}
			} catch (e) {
				ElNotification({
					// @ts-ignore
					title: e?.response?.data?.message,
					// @ts-ignore
					type: 'error'
				})
			}
		}

		onMounted(async () => {
			try {
				const response = await axios.get('/grid-size/brand-category')
				brands.value = response.data.brands
				categories.value = response.data.categories
			} catch (e) {
				console.log('err: ', e)
			}
		})

		return {
			isApply,
			gridSizeRow,
			availableGrid,
			generationObj,
			mainH1,
			brands,
			genders,
			categories,
			filterForm,
			localizeGenderGrid,
			updateRow,
			handleFilterForm,
			resetFilterForm,
			handleSubmit,
			handleIsApply,
			showTable,
			renderComponent,
			isSelectedGender,
			genderCategories,
			onChangeGender,
		}
	}
})
