
import { defineComponent, reactive, onMounted, watch } from 'vue'

export default defineComponent({
	props: {
		item: Object,
		order: Number
	},

	emits: ['updateRow'],

	setup(props, { emit }) {
		const defaultObj = reactive({
			size: '',
			footSize: '',
			intlSize: '',
			brandSize: '',
			breast: '',
			waist: '',
			hip: '',
			russianSize: ''
		})

		const propsData = reactive({ data: {} })

		const containsKey = (key: string) => {
			return Object.keys(defaultObj).includes(key)
		}

		watch(
			defaultObj,
			() => {
				emit('updateRow', { id: props.item?.id, data: { ...defaultObj }, order: props.order })
			},
			{ deep: true }
		)

		onMounted(() => {
			propsData.data = { ...props.item?.grid }
		})

		return { defaultObj, propsData, containsKey }
	}
})
